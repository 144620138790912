/** @jsx jsx */
import React from 'react'
import { jsx, Link, Image, Flex, Text, Grid, Box } from 'theme-ui'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import Logo from 'assets/logo.svg'
import 'styles/global.css'

export default function Layout(props: any) {
  return (
    <Box
      sx={{
        backgroundImage: 'url(/grain.png)',
        textRendering: 'optimizeLegibility',
      }}
    >
      <Flex
        sx={{
          position: 'fixed',
          borderBottom: '1px solid black',
          width: '100%',
          zIndex: '999999',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px 0',
          height: '70px',
        }}
        bg={'background'}
      >
        <Flex
          sx={{
            width: '100%',
            maxWidth: '1280px',
            padding: '0 8px',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Flex
            sx={{ alignItems: 'center', position: 'relative', flexGrow: '1' }}
          >
            <Link
              href="/"
              sx={{
                svg: {
                  width: '100%',
                },
                maxWidth: '120px',
                width: '100%',
                display: 'flex',
                height: '30px',
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
              }}
              title="Printado Printing Services"
            >
              <Logo />
            </Link>
          </Flex>
          <Flex
            sx={{
              position: 'relative',
              fontWeight: 'bold',
            }}
            p={1}
          >
            <Link href="/sticker/samples" variant="button.transparent" title="Samples | Printado Printing Services">
              Samples
            </Link>
            <Link ml={1} href="/sticker/pricing" variant="button.transparent" title="Pricing | Printado Printing Services">
              Pricing
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Box
        sx={{
          paddingTop: '70px',
          minHeight: ['100vh', 'calc(100vh - 120px)', 'calc(100vh - 120px)'],
        }}
      >
        {props.children}
      </Box>
      <Box
        sx={{
          padding: '20px 8px',
          borderTop: '1px solid black',
          marginTop: '20px',
        }}
      >
        <Grid
          gap={0}
          columns={[2, 3, 6]}
          sx={{
            maxWidth: '1024px',
            width: '100%',
            margin: '0 auto',
            alignItems: 'center',
            height: ['auto', '40px', '40px'],
            fontSize: '14px',
            '*': {},
          }}
        >
          <Link href="https://bit.ly/printadoFB" variant="link" title="Printado Printing Services Facebook">
            Facebook
          </Link>
          <Link href="https://bit.ly/printadomessenger" variant="link" title="Printado Printing Services Messenger">
            Messenger
          </Link>
          <Link href="https://bit.ly/printadoshopee" variant="link" title="Printado Printing Services Shopee">
            Shopee
          </Link>
          <Link href="https://bit.ly/printadoIG" variant="link" title="Printado Printing Services Instagram">
            Instagram
          </Link>
          <Text variant="link" title="Printado Printing Services Email">printadoprinting@gmail.com</Text>
        </Grid>
      </Box>
    </Box>
  )
}
