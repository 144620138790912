/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import MessengerCustomerChat from 'react-messenger-customer-chat'

export default function Layout(props: any) {
  return (
    <MessengerCustomerChat
      appId={process.env.GATSBY_MESSENGER_APP_ID}
      pageId={process.env.GATSBY_MESSENGER_PAGE_ID}
    />
  )
}
