import React from 'react'
import { Flex, Text, Box, Image, Link, Heading, Grid } from 'theme-ui'
import Compute from 'components/compute'
import { keyframes } from '@emotion/core'
import facebookIcon from 'assets/fb.svg'
import emailIcon from 'assets/emailicon.svg'
import igIcon from 'assets/ig.svg'
import starIcon from 'assets/star.svg'
import Messenger from 'components/messenger'
import SEO from 'components/seo'
import Layout from 'components/layout'

export default function Home() {
  return (
    <Layout>
      <Box>
        <SEO title="Printado Printing Services" />
        <Messenger />
        <LandingHome />
        <ContactUs />
        <Clients />
      </Box>
    </Layout>
  )
}

const Clients = () => (
  <Flex
    sx={{
      minHeight: '100vh',
      flexFlow: 'column',
    }}
  >
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Flex
        bg="primary"
        sx={{
          justifyContent: 'center',
          height: ['200px', '200px', '400px'],
          alignItems: 'center',
        }}
      >
        <Heading
          sx={{
            fontSize: ['30px', '60px', '80px'],
            textShadow: '4px 4px 0 rgba(0,0,0,0.3)',
            textAlign: 'center',
          }}
        >
          What Our Clients Say
        </Heading>
      </Flex>
    </Box>
    <Box p={[2, 4, 4]}>
      <Grid
        gap={0}
        columns={[1, 2, 4]}
        sx={{
          textAlign: 'center',
          position: 'relative',
          top: ['0', '-75px', '-110px'],
          div: {
            fontWeight: '100',
          },
          img: {
            borderRadius: '100%',
            width: ['80px', '100px', '150px'],
          },
        }}
      >
        <Box color="muted" p={1}>
          <Image src="https://i.ibb.co/58gswKV/moon-chunk.jpg" />
          <Heading>Moonchunkph</Heading>
          <Text sx={{ maxWidth: '300px', margin: '0 auto' }}>
            We are using sticker labels made by Printado Printing Services.
            Producing high quality print that goes well with our minimalist
            packaging. Feel free to hit them up!
          </Text>
          <Stars display={['block', 'block', 'none']} />
        </Box>
        <Box color="text" p={1}>
          <Image src="https://i.ibb.co/cJPJqmp/keto-blends-logo.jpg" />
          <Heading>Keto Blends PH</Heading>
          <Text sx={{ maxWidth: '300px', margin: '0 auto' }}>
            We would also like to thank EM-Designed Graphics for our logo and
            label layout and Printado Printing Services for our high quality
            stickers.
          </Text>
          <Stars display={['block', 'block', 'none']} />
        </Box>
        <Box color="muted" p={1}>
          <Image src="https://i.ibb.co/nkjVJzJ/jmex-logo.jpg" />
          <Heading>JMEX Milktea</Heading>
          <Text sx={{ maxWidth: '300px', margin: '0 auto' }}>
            Thanks to Printado Printing Services for the perfectly splendid
            waterproof stickers dahil kapag proud ka, labelan mo.
          </Text>
          <Stars display={['block', 'block', 'none']} />
        </Box>
        <Box color="muted" p={1}>
          <Image src="https://i.ibb.co/VQ6K25n/michelle-longganisa.jpg" />
          <Heading>Michelle's Special Longganisang Calumpit</Heading>
          <Text sx={{ maxWidth: '300px', margin: '0 auto' }}>
            Thank you Printado, Printing Services for providing our printing
            needs. They're always one message away.
          </Text>
          <Stars display={['block', 'block', 'none']} />
        </Box>
      </Grid>
      <Grid
        gap={0}
        columns={[3, 3, 4]}
        sx={{
          textAlign: 'center',
          display: ['none', 'none', 'grid'],
        }}
      >
        <Stars />
        <Stars />
        <Stars />
        <Stars />
      </Grid>
    </Box>
  </Flex>
)

const Stars = (props: any) => (
  <Box
    sx={{
      background: `url(${starIcon})`,
      backgroundRepeat: 'space',
      backgroundSize: '20px',
      height: '20px',
      width: '100px',
      margin: '5px auto',
      ...props,
    }}
  ></Box>
)
const LandingHome = () => (
  <Flex
    sx={{
      minHeight: '100vh',
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    <Box>
      <Box
        sx={{
          ...wavyCss,
          right: ['-28%', '-28%', '-15%'],
          backgroundCcolor: 'rgba(247, 192, 4, 0.2)',
          animation: `${waves} 6s infinite linear`,
        }}
      ></Box>
      <Box
        sx={{
          ...wavyCss,
          backgroundColor: 'rgba(247, 192, 4, .5)',
          animation: `${waves} 7s infinite linear`,
        }}
      ></Box>
    </Box>
    <Flex
      p={2}
      sx={{
        alignItems: 'center',
        width: '100%',
        margin: '0 auto',
        justifyContent: 'space-around',
      }}
    >
      <Box sx={{ maxWidth: '500px' }}>
        <Compute />
      </Box>
      <Image
        src="https://i.ibb.co/BggMgLH/printado.jpg"
        alt=""
        sx={{
          borderRadius: '100%',
          boxShadow:
            '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
          width: ['25%', '30%', '30%'],
          position: ['absolute', 'relative', 'relative'],
          display: ['block', 'block', 'block'],
          top: ['5px', '0', '0'],
          right: ['5px', '0', '0'],
        }}
        ml={[0, 2, 2]}
      />
    </Flex>
  </Flex>
)

const wavyCss = {
  position: 'absolute',
  width: ['450px', '700px', '800px'],
  height: ['450px', '700px', '800px'],
  borderTopLeftRadius: '40%',
  borderTopRightRadius: '45%',
  borderBottomLeftRadius: '35%',
  borderBottomRightRadius: '40%',
  right: ['-30%', '-30%', '-18%'],
  top: '-5%',
}

const ContactUs = () => (
  <Flex
    sx={{
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexFlow: 'column-wrap',
      overflowX: 'hidden',
      position: 'relative',
    }}
    p={4}
  >
    <Box sx={{ zIndex: 1, textShadow: '2px 2px 0 rgba(0,0,0,0.3)' }}>
      <Heading
        mb={2}
        sx={{
          fontSize: ['35px', '40px', '50px'],
        }}
      >
        Contact Us
      </Heading>
      <Flex
        sx={{
          flexFlow: 'column',
          img: { height: ['15px', '20px', '30px'], marginRight: '12px' },
          a: {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Link
          href="https://bit.ly/printadoFB"
          color="text"
          sx={{
            fontSize: ['20px', '20px', '35px'],
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          title="Printado Printing Services Facebook"
        >
          <Image src={facebookIcon} />
          Facebook
        </Link>
        <Link
          href="https://bit.ly/printadoIG"
          color="text"
          sx={{
            fontSize: ['20px', '20px', '35px'],
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          title="Printado Printing Services Instagram"
        >
          <Image src={igIcon} />
          Instagram
        </Link>
        <Link
          href="mailto:printadoprinting@gmail.com"
          color="text"
          sx={{
            fontSize: ['15px', '20px', '18px'],
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          
          title="Printado Printing Services Email"
        >
          <Image src={emailIcon} />
          printadoprinting@gmail.com
        </Link>
      </Flex>
    </Box>
    <Box
      sx={{
        position: ['absolute', 'absolute', 'relative'],
        opacity: ['0.3', '0.3', '1'],
      }}
    >
      <Flex
        sx={{
          margin: '0 auto',
          overflow: 'hidden',
          width: '400px',
          height: '400px',
          ...cssSlideIn,
        }}
      >
        <Image src="https://i.ibb.co/K2tnhMp/keto-blends.jpg" />
        <Image src="https://i.ibb.co/0hSZqJs/homeppy.jpg" />
        <Image src="https://i.ibb.co/pRxbyYH/dessert-box.jpg" />
        <Image src="https://i.ibb.co/sFB3gLF/tga-gourmet.jpg" />
        <Image src="https://i.ibb.co/0D2pPZC/woke-n-baked.jpg" />
        <Image src="https://i.ibb.co/80sVDGw/tea-la-vie.jpg" />
        <Image src="https://i.ibb.co/DMkXwsb/lettys.jpg" />
        <Image src="https://i.ibb.co/pWMhn8B/la-sweet-tea.jpg" />
      </Flex>
    </Box>
  </Flex>
)
const slideIn = keyframes`
  0% {left: 400px;}
  1% {left: 0;}
  10% {left: 0;}
  11% {left: -400px;}
  100%{left: -400px;}
`
const waves = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`

const cssSlideIn = {
  backgroundImage: 'url(https://i.ibb.co/BggMgLH/printado.jpg)',
  position: 'relative',
  backgroundSize: '100%',
  boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  img: {
    animationName: slideIn,
    animationDuration: '30s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    minWidth: '340px',
    width: '400px',
    left: '400px',
    position: 'absolute',
    ':nth-of-type(1)': { animationDelay: '0s' },
    ':nth-of-type(2)': { animationDelay: '5s' },
    ':nth-of-type(3)': { animationDelay: '10s' },
    ':nth-of-type(4)': { animationDelay: '15s' },
    ':nth-of-type(5)': { animationDelay: '20s' },
    ':nth-of-type(6)': { animationDelay: '25s' },
    ':nth-of-type(7)': { animationDelay: '30s' },
  },
}
